// @ts-nocheck
import React, { useEffect } from 'react'
import { graphql, navigate } from 'gatsby'
import { getGlobals, useLink } from '@ggs/gatsby/lib'
import icons from '@ggs/components/icons'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import { colors } from '@ggs/styles'
import { find } from 'lodash'
import { SEO } from '@ggs/gatsby/components/layout'

const { STORES, LANG_CODES } = getGlobals()

/**
 *
 * @param {Object} props
 * @return {null}
 */
export default function Index(props) {
  const userLang = props?.pageContext?.i18n?.language
  const site = props?.queries?.site
  // const layoutMeta = useLayoutMeta()
  const urlData = useLink()({ href: '/404' })
  let { storeAlias, currentLang } = urlData
  let resolvedLang = currentLang || userLang

  // If the store pattern isn't a valid store, default to global.
  if (!find(STORES, ['url', storeAlias])) {
    storeAlias = 'ca'
  }
  if (!LANG_CODES.includes(resolvedLang)) {
    resolvedLang = 'en-CA'
  }
  const {
    data: {
      metaTags,
      metaSchema,
      title,
      layoutMeta
    } = {},
  } = props?.pageContext ?? {}
  // Build final target
  const target = `/${storeAlias}/${resolvedLang}/404?path=${
    props.location.pathname
  }`

  useEffect(() => {
    setTimeout(() => {
      navigate(target, { replace: true })
    }, 100)
  }, [])

  const { lang = {}, canonicalUrls } = layoutMeta || {}

  const langCode = lang ?? 'en-CA'

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      style={{ height: '100vh', width: '100vw', backgroundColor: colors.ultraLightBlue }}
      flexDirection={'column'}
    >
      <Grid item sx={{ mt: '40vh', mb: 2 }}>
        {icons.TrudellLogo}
      </Grid>
      <Grid item>
        <SEO
          title={title ?? '404'}
          site={site}
          metaTags={metaTags}
          metaSchema={metaSchema}
          metaCanonical={canonicalUrls}
          lang={langCode}
        />
        <CircularProgress size={100}/>
      </Grid>
    </Grid>
  )
}

export const query = graphql`
  query fourOhFourFallbackPage($language: String!) {
    locales: allLocale(filter: { ns: { in: ["global"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
